
import { ref, reactive } from 'vue';
import { computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';

import { Loader } from '../../../components/ui';
import { ProfileCard } from '../../../components/common';
import { useStores } from '@/store/Stores';

const defaultModel = {
  name: '',
  email: '',
  phone: '',
  credits: '',
  tokens: ''
};

export default {
  components: {
    InputNumber,
    InputText,
    Button,
    ProfileCard,
    Loader
  },
  setup() {
    const { companyUserStore, companyUserStoreState } = useStores();

    const { t } = useI18n();
    const toast = useToast();

    const data = ref();
    const isLoading = computed(() => companyUserStoreState.isLoading);
    const isError = computed(() => companyUserStoreState.isError);
    const isSubmiting = computed(() => companyUserStoreState.isSubmiting);

    const rules = reactive({
      email: { required, email },
      name: { required },
      phone: { required }
    });

    function reset(params) {
      data.value = defaultModel;
      v$.value.$reset();
    }

    const v$ = useVuelidate(rules, data);

    const handleSubmit = (isFormValid) => {
      v$.value.$touch();
      if (!isFormValid) {
        return;
      }

      companyUserStore.createNew(data.value, reset, t, toast);
    };

    return {
      v$,
      data,
      handleSubmit,
      isLoading,
      isError,
      isSubmiting
    };
  }
};
